<template>
  <b-form>
    <BButton
      variant="relief-primary"
      class="mr-0 btn-sm-block mt-25 pl-1"
      @click="descargarJson()"
    >
    <feather-icon
      icon="DownloadIcon"
      size="16"
      class="mr-0 mr-sm-50"
    />
      Descargar Archivo 
    </BButton>
  </b-form>
</template>

<script>
import {
  BForm, BButton,
} from 'bootstrap-vue'

import { mapActions } from 'vuex'

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Componentes //
import colLinea from '../../../components/Form/colLinea.vue'

export default {
  components: {
    BForm, BButton,
    // Componentes //
    colLinea,
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions({
      fetchValidacionesStandar: 'establecimientos/fetchValidacionesStandar',
    }),
    async descargarJson() {
      const response = await this.fetchValidacionesStandar()
      console.log('response :', response)
    },
  },
}
</script>

<style>

</style>
